import { useEffect } from 'react';
import classes from './CareerPage.module.css';
import CareerCard from '../Components/Career/CareerCard';

const CareerPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (

    <div className={classes.hold}>
      <CareerCard></CareerCard>
    </div>
  )
}

export default CareerPage;