import classes from './Exam.module.css';

const Exam = () => {
    return <div className={classes.container}>
        <div className={classes.headHold}>
            <div className={classes.headHold2}>
                <span className={classes.head}>We also book
                    <span className={classes.exam}> exams</span> for candidates.</span>
            </div>
            <div className={classes.divlistHold}>
                <ul className={classes.listHold}>
                    <li>Method-ONLINE</li>
                    <li>Time-4 Hours</li>
                    <li>100 Questions</li>
                    <li>Type - MCQ</li>
                    <li>Exam pattern- Open book test</li>
                </ul>
            </div>
        </div>


    </div>
};

export default Exam;

