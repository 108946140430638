import { useEffect } from 'react';
import classes from './CPC.module.css';
import pic from './asset/cpc.jpeg';
import pic1 from './asset/calendar.png';
import pic2 from './asset/mortarboard.png';
import pic3 from './asset/exclamation-mark-in-a-circle.png';
import arrow from './asset/next.png';

import Cpc from '../Components/Bmct/Cpc';
import { Link } from 'react-router-dom';

const CPC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (<div className={classes.contain}>
        <div className={classes.hold}>
            <div className={classes.left}>
                <p className={classes.head}>Human Anatomy And Physiology.</p>
                <p className={classes.txt}>This course covers human anatomy and lays out the physiology of the cardiovascular, respiratory, reproductive, skeletal and nervous systems, among others. We explore the amazing inner workings of the body, which is a complex collection of interacting systems that carry out the important functions that let you move, think, feel and live.</p>
                <p className={classes.txt}>Certified coders earn  -  30% HIGHER  -  than Non-certified ones.</p>

                <img src={pic} alt='' className={classes.mainImg}></img>
            </div>
            <div className={classes.right}>
                <div className={classes.card}>
                    <p className={classes.cardHead}>Course Features</p>
                    <ul>
                        <li className={classes.list}>
                            <img src={pic1} className={classes.icon}></img>
                            <span className={classes.cardTxt1}>Weekdays</span>
                            <span className={classes.cardTxt2}>60 Days / 3 Hrs per day</span>
                        </li>
                        <li className={classes.list}>
                            <img src={pic2} className={classes.icon}></img>
                            <span className={classes.cardTxt1}> Institution</span>
                            <span className={classes.cardTxt2}>Sai Healthcare</span>
                        </li>
                        <li className={classes.list}>
                            <img src={pic3} className={classes.icon}></img>
                            <span className={classes.cardTxt1}>Materials</span>
                            <span className={classes.cardTxt2}>Training & Test Materials</span>
                        </li>
                    </ul>
                    <Link className="list" to='/contact'><button className={classes.courseBtn}>Enroll Course</button></Link>
                </div>
            </div>


        </div>
        <Cpc></Cpc>

        <div className={classes.last}>
            <div className={classes.lastL}>
                <p className={classes.lastLh1}>WE ARE WITH YOU EVERY STEP GET TRAINED EASILY.</p>
                <p className={classes.lastLh2}>Make your Career Faster, Safer & Better.</p>
            </div>
            <div className={classes.lastR}>
                <div className={classes.lastRIn}>
                    <p className={classes.get}>Get Started Now</p>
                    <Link to='/Contact'><button className={classes.lastRBtn}><img src={arrow} className={classes.arrow}></img></button></Link>
                </div>
            </div>
        </div>
    </div >
    )
}

export default CPC;