import classes from './Welcome.module.css';
import img2 from './img2.jpeg';
import logo from '../Nav/sailogo1.png';

import Bounce from 'react-reveal/Bounce';


const Welcome = () => {

    return <div className={classes.hold}>
        <div className={classes.left}>
            <div>
                <Bounce right>
                    <span className={classes.line1}>Welcome To Sai Healthcare!</span>
                </Bounce>
            </div>
            <div>
                <span className={classes.line2}>We Are With You</span>
                <span className={classes.line3}> Every Step Get</span>
            </div>
            <div>
                <span className={classes.line4}>Trained Easily.</span>
            </div>

            <div className={classes.line5hold}>
                <span className={classes.line5}>A Medical Coding and Billing
                    Training Academy that provides certification training programs
                    to corporate and individuals, moreover to freshers to begin
                    their career and also to experienced individuals to enhance
                    their career in the field of Medical Coding and
                    billing/healthcare RCM industry</span>
            </div>

        </div>

        <div className={classes.right}>
            <img src={logo} className={classes.image}></img>
        </div>
    </div>
};

export default Welcome;