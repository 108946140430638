import { useEffect } from 'react';
import classes from './CPC.module.css';
import pic from './asset/cpc3.jpeg';
import pic1 from './asset/calendar.png';
import pic2 from './asset/mortarboard.png';
import pic3 from './asset/exclamation-mark-in-a-circle.png';
import arrow from './asset/next.png';

import Cpc3 from '../Components/Bmct/Cpc3';
import { Link } from 'react-router-dom';

const CPC3 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (<div className={classes.contain}>
        <div className={classes.hold}>
            <div className={classes.left}>
                <p className={classes.head}>CPC Training Is Purely A Career Growth Which Takes Your Medical Coding Career To Great Leap.</p>
                <p className={classes.txt}>CPC Certification provides the candidates to achieve unique position in their career and a good growth in their present work. Time management is much concerned and extensive ideas to maintain time during the exam is provided by our skilled trainers</p>
                <p className={classes.txt}>Certified coders earn  -  30% HIGHER  -  than Non-certified ones.</p>

                <img src={pic} alt='' className={classes.mainImg}></img>
            </div>
            <div className={classes.right}>
                <div className={classes.card}>
                    <p className={classes.cardHead}>Course Features</p>
                    <ul>
                        <li className={classes.list}>
                            <img src={pic1} className={classes.icon}></img>
                            <span className={classes.cardTxt1}>Weekdays</span>
                            <span className={classes.cardTxt2}>25 Days / 4 Hrs per day</span>
                        </li>
                        <li className={classes.list}>
                            <img src={pic2} className={classes.icon}></img>
                            <span className={classes.cardTxt1}> Institution</span>
                            <span className={classes.cardTxt2}>Sai Healthcare</span>
                        </li>
                        <li className={classes.list}>
                            <img src={pic3} className={classes.icon}></img>
                            <span className={classes.cardTxt1}>Materials</span>
                            <span className={classes.cardTxt2}>Training & Test Materials</span>
                        </li>
                    </ul>
                    <Link className="list" to='/contact'><button className={classes.courseBtn}>Enroll Course</button></Link>
                </div>
            </div>


        </div>
        <Cpc3></Cpc3>

        <div className={classes.last}>
            <div className={classes.lastL}>
                <p className={classes.lastLh1}>WE ARE WITH YOU EVERY STEP GET TRAINED EASILY.</p>
                <p className={classes.lastLh2}>Make your Career Faster, Safer & Better.</p>
            </div>
            <div className={classes.lastR}>
                <div className={classes.lastRIn}>
                    <p className={classes.get}>Get Started Now</p>
                    <Link to='/Contact'><button className={classes.lastRBtn}><img src={arrow} className={classes.arrow}></img></button></Link>
                </div>
            </div>
        </div>
    </div>
    )
}

export default CPC3;