import { Outlet } from "react-router-dom";
import Foot2 from "../Components/Foot/Foot2";
import Navi from "../Components/Nav/Navi";

const RootLayout = () => {
    return <div>
        <Navi></Navi>
        <Outlet></Outlet>
        <Foot2></Foot2>
    </div>
};

export default RootLayout;