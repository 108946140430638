import { useEffect } from 'react';
import Form1 from '../Components/Form/Form1';
import MySlider1 from '../Components/Myslider/MySlider';
import Card1 from '../Components/Card1/Card1';
import Welcome from '../Components/Welcome/Welcome';
import Num from '../Components/Num/Num';
import Cycle from '../Components/Cycle/Cycle';
import Popular from '../Components/Popular/Popular';
import Popcard from '../Components/Popular/PopCard';
import Trainer from '../Components/Trainers/Trainer';
import Testimonial from '../Components/Tesimonial/Testimonial';
import TestSlider from '../Components/Tesimonial/TestSlider';
import Whatsapp from '../Components/Whatsapp/Whatsapp';


const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return <div>
        <MySlider1></MySlider1>
        <Form1></Form1>
        <Card1></Card1>
        <Welcome></Welcome>
        <Num></Num>
        <Cycle></Cycle>
        <Popular></Popular>
        <Popcard></Popcard>
        <Trainer></Trainer>
        <Testimonial></Testimonial>
        <TestSlider></TestSlider>
        <Whatsapp></Whatsapp>



    </div>
};

export default Home;