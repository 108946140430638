import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import classes from './MyCard.module.css';
//style={{ width: '18rem' }}

function MyCard(props) {
  return (
    <Card className={classes.hold}>
      <Card.Img variant="top" src={props.image} />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>
          {props.mytext}
        </Card.Text>

      </Card.Body>
    </Card>
  );
}

export default MyCard;