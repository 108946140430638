import classes from './Card.module.css';

const Card = (props) => {
    return <div className={classes.hold}>
        <div className={classes.hold2}>

            <div className={classes.imghold}>
                <img src={props.image} className={classes.image}></img>
            </div>

            <div className={classes.txthold}>
                <span className={classes.txt} >{props.txt}</span>
            </div>
        </div>
    </div>
};

export default Card;