import { useEffect } from "react";
import AboutPage from "../Components/About/AboutPage";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return <div>
        <AboutPage></AboutPage>
    </div>
};

export default About;