import React from 'react';
import classes from './Whatsapp.module.css';
import { render } from 'react-dom';
import ReactWhatsapp from 'react-whatsapp';

import what from './whatsapp.png';

const Whatsapp = () => {
    return <div className={classes.hold}>
        <ReactWhatsapp number="+919404074769" message="Hello" className={classes.btn}>
            <img src={what} className={classes.img}></img>
        </ReactWhatsapp>
    </div>
}



//render(<App />, document.getElementById('root'));

export default Whatsapp; 