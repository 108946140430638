import { Link } from 'react-router-dom';
import classes from './Cycle.module.css';
import cycle from './cycle.webp';
import arrow from './arrows.png';
//left-right mismatch
const Cycle = () => {
    return <div className={classes.hold}>
        <div className={classes.right}>
            <img src={cycle} className={classes.image}></img>
        </div>

        <div className={classes.left}>
            <div className={classes.titleHold}>
                <span className={classes.title}>Revenue Cycle </span>
                <span className={classes.titleMgm}>Management</span>
            </div>

            <div className={classes.leftTxtHold}>
                <span className={classes.leftTxt}>Is all about getting apt reimbursement for
                    the provider and facilities forthe services they provide
                    to the patients from insurance companies</span>
            </div>

            <div className={classes.btndiv}>
                <div className={classes.knowHold}><span className={classes.know}>About RCM</span></div>
                <div className={classes.btnHold}>
                    <button className={classes.arrowBtn}>
                        <Link to='/courses'>
                            <img src={arrow} className={classes.arrowImg}></img>
                        </Link>
                    </button>
                </div>

            </div>
        </div>
    </div>
};

export default Cycle;