import { useState } from 'react';
import classes from './Bmct.module.css';
import tick from './checkmark.png';

const Cpc3 = () => {
    const [top, setTop] = useState(true);
    const [high, setHigh] = useState(false);
    const [dur, setDur] = useState(false);

    const topHnd = () => {
        setTop(true);
        setHigh(false);
        setDur(false);
    };
    const highHnd = () => {
        setTop(false);
        setHigh(true);
        setDur(false);
    };
    const durHnd = () => {
        setTop(false);
        setHigh(false);
        setDur(true);
    };

    return (
        <div className={classes.hold}>
            <div className={classes.btnhold}>
                <button className={classes.btn} onClick={topHnd}>Topics Covered</button>
                <button className={classes.btn} onClick={highHnd}>Highlights</button>
                <button className={classes.btn} onClick={durHnd}>Duration</button>
            </div>

            {top && <div className={classes.card}>
                <p className={classes.head}>Topics Covered</p>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>All sections (i.e.) E&M (Evaluation and Management), Surgery, Anesthesia, Radiology, Pathology, Medicine.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>ICD-10 CM coding guidelines.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>HCPCS Coding guidelines.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Compliance Regulatory.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Anatomy, Physiology and Medical Terminology.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Medical Billing.</span></p>
                </div>



            </div>}

            {high && <div className={classes.card}>
                <p className={classes.head}>Highlights Of CPC</p>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Extensive ideas to maintain Time management during Exam for candidates.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Free study materials and Manuals.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>7 Mock Assessment with in-depth review on each Mock assessment.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Mock Centre for exam practise (This will give the real feel of AAPC exam).</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Pre-exam review helps candidates to revise the concepts and increase confidence.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Various shortcuts to crack the exam in an effective way.</span></p>
                </div>

            </div>}

            {dur && <div className={classes.card}>
                <p className={classes.head}>Course Duration</p>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Duration : 60 days (Weekdays Days | 3 hours per day)</span></p>
                </div>



            </div>}
        </div>
    )
}

export default Cpc3;