import { useEffect } from 'react';
import classes from './CPC.module.css';
import pic from './asset/cpma.jpg';
import pic1 from './asset/calendar.png';
import pic2 from './asset/mortarboard.png';
import pic3 from './asset/exclamation-mark-in-a-circle.png';
import arrow from './asset/next.png';

import Cpma from '../Components/Bmct/Cpma';
import { Link } from 'react-router-dom';

const CPMA = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (<div className={classes.contain}>
        <div className={classes.hold}>
            <div className={classes.left}>
                <p className={classes.head}>Enhance Your Career With A Course In Medical Terminology</p>
                <p className={classes.txt}>Medical terminology is the language spoken in healthcare environments. Understanding medical terminology is essential for efficient communication and operation between physicians, non-clinical staff and healthcare organizations. For medical coders and billers, job performance depends on fluency in medical terminology.</p>
                <p className={classes.txt}>Certified coders earn  -  30% HIGHER  -  than Non-certified ones.</p>

                <img src={pic} alt='' className={classes.mainImg}></img>
            </div>
            <div className={classes.right}>
                <div className={classes.card}>
                    <p className={classes.cardHead}>Course Features</p>
                    <ul>
                        <li className={classes.list}>
                            <img src={pic1} className={classes.icon}></img>
                            <span className={classes.cardTxt1}>Weekdays</span>
                            <span className={classes.cardTxt2}>25 Days / 4 Hrs per day</span>
                        </li>
                        <li className={classes.list}>
                            <img src={pic1} className={classes.icon}></img>
                            <span className={classes.cardTxt1}>Weekends</span>
                            <span className={classes.cardTxt2}>50 Days / 2 Hrs per day</span>
                        </li>
                        <li className={classes.list}>
                            <img src={pic2} className={classes.icon}></img>
                            <span className={classes.cardTxt1}> Institution</span>
                            <span className={classes.cardTxt2}>Sai Healthcare</span>
                        </li>
                        <li className={classes.list}>
                            <img src={pic3} className={classes.icon}></img>
                            <span className={classes.cardTxt1}>Materials</span>
                            <span className={classes.cardTxt2}>Training & Test Materials</span>
                        </li>
                    </ul>
                    <Link className="list" to='/contact'><button className={classes.courseBtn}>Enroll Course</button></Link>
                </div>
            </div>


        </div>
        <Cpma></Cpma>

        <div className={classes.last}>
            <div className={classes.lastL}>
                <p className={classes.lastLh1}>WE ARE WITH YOU EVERY STEP GET TRAINED EASILY.</p>
                <p className={classes.lastLh2}>Make your Career Faster, Safer & Better.</p>
            </div>
            <div className={classes.lastR}>
                <div className={classes.lastRIn}>
                    <p className={classes.get}>Get Started Now</p>
                    <Link to='/Contact'><button className={classes.lastRBtn}><img src={arrow} className={classes.arrow}></img></button></Link>
                </div>
            </div>
        </div>
    </div>
    )
}

export default CPMA;