import classes from './CareerCard.module.css';
import brief from './asset/briefcase.png';
import loc from './asset/location.png';
import right from './asset/right.png';
import call from './asset/call.png';

const CareerCard = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.card}>
                <span className={classes.cardHeadhold}>
                    <img src={brief} className={classes.icon}></img>
                    <span className={classes.cardHead}>Openings For HR Executive (Only Female Candidates)</span>
                </span>
                <p className={classes.apply}>Apply Now</p>

                <span className={classes.loc}><img src={loc} className={classes.iconLoc}></img>Pune</span>
                <p className={classes.exp}>Experience:</p>
                <span className={classes.detail}><img src={right} className={classes.iconR}></img>2+ Years</span>

                <p className={classes.exp}>Skills:</p>
                <span className={classes.detail}><img src={right} className={classes.iconR}></img>Good communication & interpersonal skills</span>
                <p className={classes.detail}><img src={right} className={classes.iconR}></img>Good knowledge in MS Office.</p>
                <span className={classes.detail}><img src={call} className={classes.iconR}></img>+91922780281</span>

                <div><button className={classes.btnReg}>Register</button></div>
            </div>
        </div>
    )
}

export default CareerCard;