import classes from './Image.module.css';
import Card from './Card';
import img1 from './1.jpeg';
import img2 from './2.jpeg';
import img3 from './3.jpeg';
import img4 from './4.jpeg';
import img5 from './5.jpeg';
import img6 from './6.jpeg';
import img7 from './7.jpeg';


const arr = [{ pic: img3, txt: '1. How to read the books, how content is placed, understanding all books' },
{ pic: img1, txt: '2. Modifires and POS' },
{ pic: img4, txt: '3. Medical terminologies anatomy prefix and suffix' },
{ pic: img5, txt: '4. CPT- Chapter wise in details' },
{ pic: img6, txt: '5. ICD -10-cm CONVENTION  and chapter-wise guidelines' },
{ pic: img7, txt: '6. HCPSC Level II – Understanding different codes and book placement and drugs category' }];

const Image = () => {
    return <div className={classes.hold}>
        {arr.map(item => <Card image={item.pic} txt={item.txt}></Card>)}
    </div>
};

export default Image;