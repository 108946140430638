import { useState } from 'react';
import classes from './Bmct.module.css';
import tick from './checkmark.png';

const Cpc = () => {
    const [top, setTop] = useState(true);
    const [high, setHigh] = useState(false);
    const [dur, setDur] = useState(false);

    const topHnd = () => {
        setTop(true);
        setHigh(false);
        setDur(false);
    };
    const highHnd = () => {
        setTop(false);
        setHigh(true);
        setDur(false);
    };
    const durHnd = () => {
        setTop(false);
        setHigh(false);
        setDur(true);
    };

    return (
        <div className={classes.hold}>
            <div className={classes.btnhold}>
                <button className={classes.btn} onClick={topHnd}>Topics Covered</button>
                <button className={classes.btn} onClick={highHnd}>Highlights</button>
                <button className={classes.btn} onClick={durHnd}>Duration</button>
            </div>

            {top && <div className={classes.card}>
                <p className={classes.head}>Topics Covered</p>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}> Transmission of neural impulses.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Describe the structure and functions of blood and blood vessels, the heart, the digestive, muscular & nervous systems</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Identify the development of offspring during the intrauterine process.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Describe the anatomy and physiology of the human skeletal system.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Outline the structure and functions of the respiratory system, sensory organs and skeleton</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>State the workings of the male and female reproductive systems.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>HCPCS Coding guidelines.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Explain the mechanics and nervous control of skeletal muscles.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Identify the purpose of the human nervous system</span></p>
                </div>

            </div>}

            {high && <div className={classes.card}>
                <p className={classes.head}>Knowledge & Skills You Will Learn</p>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Anatomy.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Science.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Biology.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Physiology</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Human Anatomy</span></p>
                </div>

            </div>}

            {dur && <div className={classes.card}>
                <p className={classes.head}>Course Duration</p>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Duration : 25 days (Weekdays Days | 4 hours per day) or 50 days (Weekdays Days | 2 hours per day)</span></p>
                </div>



            </div>}
        </div>
    )
}

export default Cpc;