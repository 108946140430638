import classes from './Flexible.module.css';
import comp from './computer.png';

const Flexible = () => {
    return <div>
        <div className={classes.headHold}>
            <span className={classes.head}>Training Batches</span>
        </div>

        <div className={classes.rectHold}>
            <div className={classes.rect}>
                <img src={comp} className={classes.img1}></img>
                <span className={classes.week}>Weekdays</span>
                <span className={classes.time}>10:00AM to 12:00PM</span>
                <span className={classes.offline}>OFFLINE</span>
            </div>

            <div className={classes.rect}>
                <img src={comp} className={classes.img2}></img>
                <span className={classes.week}>Weekdays</span>
                <span className={classes.time}>2:30AM to 4:30PM</span>
                <span className={classes.offline}>OFFLINE</span>
            </div>
        </div>

        <div className={classes.rectHold2}>
            <img src={comp} className={classes.img1}></img>
            <span className={classes.week}>Weekdays</span>
            <div className={classes.timeHold}>
                <span className={classes.time}>7:30AM to 9:30AM</span>
                <span className={classes.time}>11:00AM to 1:00PM</span>
                <span className={classes.time}>5:30PM to 7:30PM</span>
                <span className={classes.time}>7:00PM to 9:00PM</span>
                <span className={classes.time}>8:30PM to 10:30PM</span>
            </div>
            <span className={classes.offline}>ONLINE</span>
        </div>
    </div>
};

export default Flexible;