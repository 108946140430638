import { Link } from 'react-router-dom';
import './Foot.css';
import heart from './heart.gif';
import mail from './mail.png';
import insta from './instagram.png';
import linked from './linkedin.png';
import phone from './smartphone.png';
import face from './face.png';
import arrow1 from './arrow1.png';
import logo from '../Nav/sailogo1.png';

const Foot2 = () => {
    return <div className='footholder'>
        <div className='foothold'>
            <div className='footsubhold footsubhold1'>
                <div><span className='footheading'>SAI HEALTHCARE</span></div>
                <div><span className='foottext'>A medical coding and billing training academy that provides certification
                    training programs to corporates and individuals. moreover to freshers
                    to begin their career and also to experienced individuals to enahnce their
                    career in the field of medical coding and billing/healthcare RCM industry</span></div>

            </div>
            <div className='footsubhold footsubhold2'>
                <div><span className='footheading'>USEFUL LINKS</span></div>
                <div><span className='foottext'><Link style={{ color: 'white', textDecoration: 'none' }} to='/about'>About Us</Link></span></div>
                <div><span className='foottext'><Link style={{ color: 'white', textDecoration: 'none' }} to='/courses'>Courses & Training</Link></span></div>
                <div><span className='foottext'><Link style={{ color: 'white', textDecoration: 'none' }} to='/courses'>Courses</Link></span></div>
                <div><span className='foottext'><Link style={{ color: 'white', textDecoration: 'none' }} to='/courses'>Training</Link></span></div>
                <div><span className='foottext'><Link style={{ color: 'white', textDecoration: 'none' }} to='/Training'>Exams</Link></span></div>
            </div>

            <div className='footsubhold footsubhold3'>
                <div><span className='footheading'>CONTACT US</span></div>
                <div><span className='foottext'>Office No. 3, First Floor, Pashankar Auto pvt Ltd.
                    Jangali Maharaj Road Near Shivajinagar, Pune-411005</span></div>
                <div className='mailHold2'><img src={phone} className='iconImg'></img><p className='num'>+919404074769</p></div>
                <div className='mailHold2'><img src={phone} className='iconImg'></img><p className='num'>+918530015155</p></div>
                <div className='mailHold'><img src={mail} className='iconImg'></img><span className='num'>saihccpune@gmail.com</span></div>
            </div>

        </div>
        <hr className='footline'></hr>

        <div className='footiconHold'>
            <a target="_blank" href='https://www.instagram.com/saihccpune/'><img src={insta} className='iconImg'></img></a>
            <a target="_blank" href='https://www.linkedin.com/in/sai-hcc-61105527b/'><img src={linked} className='iconImg'></img></a>
            <a target="_blank" href='https://www.facebook.com/in/sai-hcc-61105527b/'><img src={face} className='iconImg1'></img></a>
        </div>

        <div className='copy'>Copyright &copy; 2024 SaiHCC | Made with <img src={heart} className='footimage'></img> by CORTICA WEB SOLUTIONS PVT LTD</div>
        <button className='btn' onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}
        >
            <div className='Arrow'><img src={arrow1} className='scrollbtn'></img></div>
        </button>
    </div >



};

export default Foot2;