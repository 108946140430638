import classes from './Why.module.css';
import book from './asset/book.png';
import per from './asset/per.png';
import contact from './asset/contact.png';
import study from './asset/study.png';
import bal from './asset/bal.png';
import goal from './asset/goal.png';

const Why = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.headhold}><span className={classes.head}>WHY CHOOSE
                <span className={classes.colour}> SAI HEALTH CARE</span></span>
            </div>

            <div className={classes.contenthold}>
                <div className={classes.left}>
                    <div className={classes.leftL}>
                        <img src={per} className={classes.icon}></img>
                        <img src={contact} className={classes.icon}></img>
                        <img src={study} className={classes.icon}></img>
                        <img src={bal} className={classes.icon}></img>
                        <img src={goal} className={classes.icon}></img>
                    </div>

                    <div className={classes.leftR}>
                        <div>
                            <p className={classes.paraHead}>100% Pass Percentage(In AAPC Exams)</p>
                            <p className={classes.parabody}> Tie up with Top MNC’s in Chennai like CTS, Omega, Ajuba, Sutherland, AGS etc., so Reliability for Job is assured.</p>
                        </div>

                        <div>
                            <p className={classes.paraHead}>CPC Certified Trainers</p>
                            <p className={classes.parabody}>More than 8 years experienced CPC Certified trainers. Comprehensive Training focused to face real time coding issues on your career.</p>
                        </div>

                        <div>
                            <p className={classes.paraHead}>Free Study Materials</p>
                            <p className={classes.parabody}>Manuals and Bag for all the candidates joining the course. Mementos Exclusive for topper of the Batch</p>
                        </div>

                        <div>
                            <p className={classes.paraHead}>Exclusive Mock Exams</p>
                            <p className={classes.parabody}>Exclusive Mock Exam center for AAPC exams practice & Mock interview sessions for freshers to face the Real time interviews. Soft skill training and motivation sessions for freshers</p>
                        </div>

                        <div>
                            <p className={classes.paraHead}>Focus Certificate</p>
                            <p className={classes.parabody}>FOCUS Certificate is valid and accepted in all healthcare BPOs across India. All the FOCUS candidates who completed the training program are placed in leading MNCs across the country.</p>
                        </div>
                    </div>
                </div>
                <div className={classes.right}>
                    <img src={book} className={classes.book} alt=''></img>
                </div>
            </div>
        </div>
    )
}

export default Why;