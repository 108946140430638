import classes from './About.module.css';
import Accord from './Accord';
import Fact from './Fact';
import img1 from './about1.jpeg';
import Why from './Why';
import AboutSlider from './AboutSlider';


const AboutPage = () => {
    return <div className={classes.holder}>
        <div className={classes.headHold}>
            <span className={classes.head}>Who We Are ?</span>
        </div>

        <div className={classes.bodyHeadHold}>
            <div className={classes.left}>
                <div><span className={classes.bodyHead}>About us</span></div>
                <div className={classes.textHold}><span className={classes.text}>A medical coding and billing training academy that provides certification
                    training programs to corporates and individuals. moreover to freshers
                    to begin their career and also to experienced individuals to enahnce their
                    career in the field of medical coding and billing/healthcare RCM industry</span></div>

                <div className={classes.AccordHold}>
                    <Accord></Accord>
                </div>
            </div>

            <div className={classes.right}>
                <div><img src={img1} className={classes.image1}></img></div>
            </div>

        </div>

        <div>
            <Fact></Fact>
        </div>
        <div>
            <Why></Why>
        </div>

        <AboutSlider></AboutSlider>
    </div>
};

export default AboutPage;