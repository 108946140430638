import classes from './Material.module.css';
import img2 from './2.jpeg';

const Material = () => {
    return <div className={classes.container}>
        <div className={classes.hold}>
            <div className={classes.left}>
                <img src={img2} className={classes.image}></img>
            </div>
            <div className={classes.right}>
                <div className={classes.headHold1}>
                    <span className={classes.head1}>Study </span>
                    <span className={classes.head2}>Material </span>
                </div>
                <div className={classes.txtHold}>
                    <span className={classes.txt}>These would be provided by us and is inclusive in the
                        fees paid. For online candidates, study material will be couriered
                        to your home/ office.</span>
                </div>
            </div>
        </div>

        <div className={classes.bottomHold}>
            <div className={classes.headHold2}>
                <span className={classes.head21}>Practice </span>
                <span className={classes.head22}>Papers</span>
                <div className={classes.txtHold}>
                    <span className={classes.txt}>Online portal access would be provided, where you can practice
                        chapter wise question papers and mock papers.</span>
                </div>
            </div>
        </div>

    </div>
};

export default Material;