import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import Home from './Pages/Home';
import About from './Pages/About';
import Courses from './Pages/Courses';

import ContactPage from './Pages/ContactPage';
import Bmct from './Pages/Bmct';
import CPC from './Pages/CPC';
import CPC3 from './Pages/CPC3';
import CPMA from './Pages/CPMA';
import CareerPage from './Pages/CareerPage';
import RootLayout from './Pages/Root';

const router = createBrowserRouter([{
  path: '/',
  element: <RootLayout></RootLayout>,

  children: [
    { path: '', element: <Home></Home> },
    { path: '/about', element: <About></About> },
    { path: '/courses', element: <Courses></Courses> },
    { path: '/contact', element: <ContactPage></ContactPage> },
    { path: '/cicPage', element: <Bmct></Bmct> },
    { path: '/CpcPage', element: <CPC></CPC> },
    { path: '/Cpc3Page', element: <CPC3></CPC3> },
    { path: '/cpmaPage', element: <CPMA></CPMA> },
    { path: '/Career', element: <CareerPage></CareerPage> },
  ]
}]);



const App = () => {
  return <RouterProvider router={router}></RouterProvider>

};

export default App;