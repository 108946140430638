import React, { Component } from "react";
import Slider from "react-slick";
import classes from './AboutSlider.module.css';
import AboutCard from "./AboutCard";

export default class AboutSlider extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 5000,
            cssEase: "linear"
        };
        return (<div className={classes.hold}>
            <div>

                <Slider {...settings}>
                    <div>
                        <AboutCard name='Shrivigneshwari murthy'
                            txt='One of the best coaching institute for CPC training, and I have learnt so many coding tips. Friendly atmosphere & doubts are cleared whenever asked. Overall experience is too good.'>
                        </AboutCard>
                    </div>
                    <div>
                        <AboutCard name='Manikandan Aaron'
                            txt='Excellent place to study for CPC exam. I scored 74% in my first attempt.Mock tests conducted here are very helpful to clear the exam. Whatsapp group is very helpful to ask doubt anytime and get clarified.'></AboutCard>
                    </div>
                    <div>
                        <AboutCard name='Shubham Shah'
                            txt='Trainers are highly professonal and supportive. I feel very grateful to Sai Healthcare Consulting and trainers. The way of teaching is really excellant.'></AboutCard>
                    </div>


                </Slider>
            </div></div>
        );
    }
}