import classes from './MySlider.module.css';
import Carousel from 'react-bootstrap/Carousel';

import s1 from './s1.jpg';
import s2 from './s2.jpg';
import a1 from './a1.avif';
import a2 from './a2.jpg';
import a3 from './a3.jpg';

import b1 from '../../Pages/asset/b1.jpeg'
import b2 from '../../Pages/asset/b2.jpeg'
import b3 from '../../Pages/asset/b3.jpeg'
import b4 from '../../Pages/asset/b4.jpeg'



function MySlider1() {
    return (
        <Carousel className={classes.hold}>
            <Carousel.Item interval={4000}>
                <img
                    className="d-block w-100"
                    src={b1}
                    alt="First slide"
                />
                <Carousel.Caption>


                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
                <img
                    className="d-block w-100"
                    src={b2}
                    alt="Second slide"
                />
                <Carousel.Caption>

                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
                <img
                    className="d-block w-100"
                    src={b3}
                    alt="Third slide"
                />
                <Carousel.Caption>


                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={4000}>
                <img
                    className="d-block w-100"
                    src={b4}
                    alt="Third slide"
                />
                <Carousel.Caption>


                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default MySlider1;