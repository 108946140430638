import { useState } from 'react';
import classes from './Bmct.module.css';
import tick from './checkmark.png';

const CicAcc = () => {
    const [top, setTop] = useState(true);
    const [high, setHigh] = useState(false);
    const [dur, setDur] = useState(false);

    const topHnd = () => {
        setTop(true);
        setHigh(false);
        setDur(false);
    };
    const highHnd = () => {
        setTop(false);
        setHigh(true);
        setDur(false);
    };
    const durHnd = () => {
        setTop(false);
        setHigh(false);
        setDur(true);
    };

    return (
        <div className={classes.hold}>
            <div className={classes.btnhold}>
                <button className={classes.btn} onClick={topHnd}>Topics Covered</button>
                <button className={classes.btn} onClick={highHnd}>Highlights</button>
                <button className={classes.btn} onClick={durHnd}>Duration</button>
            </div>

            {top && <div className={classes.card}>
                <p className={classes.head}>Topics Covered</p>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Medical Record and Healthcare Documentation Guidelines</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Medical Terminology, Anatomy and Pathophysiology.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Inpatient Coding.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Inpatient Payment Methodologies.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Outpatient Payment Methodology.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Superior knowledge of current rules, regulations, and issues regarding medical coding, compliance, and reimbursement under MS-DRG and IPPS systems..</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Knowledge of pharmacology to include common medications; their uses and side effects..</span></p>
                </div>



            </div>}

            {high && <div className={classes.card}>
                <p className={classes.head}>Highlights Of CIC</p>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Understand different types of DRGs (eg, MS-DRG or APR-DRG).</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Free study materials and Manuals.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Strong ability to integrate coding and reimbursement rule changes in a timely manner and the UB04 for proper reimbursement.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Mock Centre for exam practise (This will give the real feel of AAPC exam).</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Pre-exam review helps candidates to revise the concepts and increase confidence.</span></p>
                </div>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Various shortcuts to crack the exam in an effective way.</span></p>
                </div>

            </div>}

            {dur && <div className={classes.card}>
                <p className={classes.head}>Course Duration</p>

                <div className={classes.cardIn}>
                    <img src={tick} className={classes.tick}></img>
                    <p><span className={classes.txt}>Duration : 60 days (Weekdays Days | 3 hours per day)</span></p>
                </div>



            </div>}
        </div>
    )
}

export default CicAcc;