import classes from './Form1.module.css';
import image1 from './user.png';
import image2 from './smartphone.png';
import image3 from './pen.png';

const Form1 = () => {

    return <div className={classes.holder} >
        <div className={classes.formholder}>
            <form className={classes.form}>
                <div className={classes.inform1}>
                    <img src={image1} className={classes.img}></img>
                    <input placeholder='Name' type='text' className={classes.in}></input>
                </div>
                <div className={classes.inform2}>
                    <img src={image2} className={classes.img}></img>
                    <input placeholder='Number' type='number' className={classes.in}></input>
                </div>
                <div className={classes.inform3}>
                    <img src={image3} className={classes.img}></img>
                    <select className={classes.in}>
                        <option className={classes.option}>Choose Category</option>

                        <option className={classes.option}>CPC certification for freshers</option>
                        <option className={classes.option}>Certified Professional Coder</option>
                        <option className={classes.option}>Certified Professional Medical Auditor</option>
                        <option className={classes.option}>Speciality Training</option>
                    </select>
                </div>
                <div className={classes.btnhold}>
                    <button type='submit' className={classes.btn}>Request Quote</button>
                </div>
            </form>
        </div>
    </div>
};

export default Form1;