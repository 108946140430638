import { Link } from 'react-router-dom';
import classes from './Num.module.css';
import arrow from './arrows.png';


const Num = () => {

    return <div>
        <div className={classes.container}>
            <div className={classes.hold}>
                <div ><span className={classes.Num}>5+</span></div>
                <div ><span className={classes.numTxt}>Active Courses</span></div>
            </div>

            <div className={classes.hold}>
                <div ><span className={classes.Num}>7000+</span></div>
                <div ><span className={classes.numTxt}>Passed out Student</span></div>
            </div>
            <div className={classes.hold}>
                <div ><span className={classes.Num}>2</span></div>
                <div ><span className={classes.numTxt}>Branches</span></div>
            </div>
        </div>

        <div className={classes.btndiv}>
            <div className={classes.knowHold}><span className={classes.know}>Know More</span></div>
            <div className={classes.btnHold}>
                <button className={classes.arrowBtn}>
                    <Link to='/about'>
                        <img src={arrow} className={classes.arrowImg}></img></Link></button>
            </div>

        </div>
    </div>
};

export default Num;