import classes from './Card1.module.css';
import img1 from './1.png';
import img2 from './2.png';
import course from './course.png'

import { Link } from 'react-scroll';
import Slide from 'react-reveal/Slide';


const Card1 = () => {

    return <div className={classes.cardHold} >
        <Slide right>
            <div className={classes.card} >
                <div className={classes.imgHold}>
                    <img src={course} className={classes.img2}></img>
                </div>

                <div className={classes.headHold}>
                    <p className={classes.head}>5+ Courses</p>
                </div>

                <div className={classes.txtHold}>
                    <p className={classes.txt}>We are expert in 5+ medical coding
                        online courses like CPC, etc.</p>
                </div>
            </div>
        </Slide>

        <div className={classes.card} data-aos="flip-up">
            <div className={classes.imgHold}>
                <img src={img1} className={classes.img2}></img>
            </div>

            <div className={classes.headHold}>
                <p className={classes.head}>Expert Instructions</p>
            </div>

            <div className={classes.txtHold}>
                <p className={classes.txt}>We hire professional educators
                    with advance teaching experience who successfully mentor students
                    to achieve growth.</p>
            </div>
        </div>


    </div>

};

export default Card1;