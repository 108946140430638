import { useState } from 'react';
import classes from './Fact.module.css';

const p1 = 'Medical coding is the process of transforming the diagnosis, procedures, medical services, and equipment into universal medical alphanumeric codes. The diagnoses and procedure data are taken from medical record documentation, such as transcription of physicians notes, laboratory and radiologic results, etc';



const p2 = 'Because of the high demand for coders, wages for new medical coders grow year after year. The latest reports from the Bureau of Labor Statistics shows that, on average, a medical coder makes $16.42 an hour for an annual salary of $34,160, with variations based on location.';

const Fact = () => {
    const [para1, setPara1] = useState(true);
    const [para2, setPara2] = useState(false);

    const clkHnd1 = () => {
        setPara1(true);

    };

    const clkHnd2 = () => {
        setPara1(false);
    };

    return <div className={classes.hold}>
        <div className={classes.headhold}>
            <span className={classes.head1}>SOME IMPORTANT &nbsp;</span>
            <span className={classes.head2}> FACTS</span>
        </div>

        <div className={classes.btnHold}>
            <button className={classes.btn1} onClick={clkHnd1}>What is Medical Coding ?</button>
            <button className={classes.btn2} onClick={clkHnd2}>Medical Coding As A Career</button>
        </div>

        <div className={classes.paraHold}>
            {para1 && <p className={classes.para1}>{p1}</p>}
            {!para1 && <p className={classes.para2}>{p2}</p>}
        </div>
    </div>
};

export default Fact;