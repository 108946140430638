import classes from './Popular.module.css';


const Popular = () => {
    return <div className={classes.hold}>
        <div className={classes.title} >
            <span className={classes.pop}>Popular&nbsp;</span>
            <span className={classes.cou}> Courses</span>
        </div>

        <div>

        </div>
    </div>
};

export default Popular;