import Accordion from 'react-bootstrap/Accordion';

function Accord() {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Vision</Accordion.Header>
                <Accordion.Body>
                    To educate and create retainers in Healthcare RCM in a perception to elevate the
                    standards, eradicate deficiencies in nostalgic methods and procreate new modus
                    operandi.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Mission</Accordion.Header>
                <Accordion.Body>
                    Our goal is to steer the growth of healthcare RCM in highly
                    efficient way through quality education and consistent training.
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default Accord;