import { Component } from "react";
import './Navi.css';
import logo from './sailogo1.png';
import { Link } from 'react-router-dom';

class Navbar extends Component {
    state = { clicked: false };
    handleClick = () => {
        this.setState({
            clicked: !this.state.clicked
        })
    };
    render() {
        return (<div className="navHold">
            <nav className="navbarItems">
                <img src={logo} className="comlogo2"></img>
                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
                <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                    <li className="list"><Link to='/' className="list">Home</Link></li>
                    <li className="list"><Link to='/About' className="list">About</Link></li>
                    <li >
                        <div class="dropdown">
                            <p class="dropdownContent">Courses</p>
                            <div class="dropdown-content">
                                <Link to='/Cpc3Page'>Certified Professional Coder (CPC)</Link>
                                <Link to='/cicPage'>Certified Inpatient Coders (CIC)</Link>
                                <Link to='/CpcPage'>Anatomy and Physiology</Link>


                                <Link to='/CpmaPage'>Medical Terminology</Link>
                            </div>
                        </div>

                    </li>
                    <li className="list"><Link to='/courses' className="list">Training</Link></li>
                    <li className="list"><Link to='/Career' className="list">Career</Link></li>
                    <li className="list"><Link to='/Contact' className="list">Contact</Link></li>

                </ul>
            </nav></div >
        )
    }
};

export default Navbar;



/*
{MenuData.map((item, index) => {
    return (
        <li key={index}>
            <a href={item.url} className={item.cName}>{item.title}</a>
        </li>
    )
})}
*/