import classes from './Course.module.css';
import one from './one.jpeg';
import Image from './Image';

const Course = () => {
    return <div className={classes.hold}>
        <div className={classes.headhold1}>
            <span className={classes.head1}>Modules</span>
        </div>

        <div className={classes.top}>
            <div className={classes.left}>
                <img src={one} className={classes.image}></img>
            </div>
            <div className={classes.right}>
                <div className={classes.headHold2}>
                    <span className={classes.head2}>Introduction to Medical Coding
                        and Billing</span>
                </div>
                <div className={classes.txtHold1}>
                    <span className={classes.txt1}>This is a 60-minute, introductory, mini course in
                        medical billing and coding. This course is designed for
                        individuals who are interested in a career as a medical
                        biller and coder but are not familiar with the tasks. We
                        begin the course with an overview of the medical biller
                        and coder's tasks. Then the student will learn how to
                        assign codes using ICD-10-CM, CPT and HCPCS, in a family
                        practice setting. Additionally, the student will be
                        exposed to the types of tasks performed by a medical
                        biller, such as, Claim Status follow-ups, Payment
                        posting and the Patient collections process. At the
                        close of this course we will discuss the education
                        and experience options that a student can take to
                        become a medical biller and coder.</span>
                </div>
            </div>
        </div>

        <div>
            <Image></Image>
        </div>


    </div>

};

export default Course;