import classes from './Trainer.module.css';

import trainer1 from './trainer11.png';
import trainer2 from './trainer22.png';
import trainer3 from './trainer33.png';
import trainer4 from './trainer44.png';
import a1 from './a11.png';
import a3 from './a33.png';
import a4 from './a44.png';

const Trainer = () => {
    return <div>
        <div className={classes.titleHold}>
            <span className={classes.title}>Our Amazing Team</span>
        </div>

        <div className={classes.imgHold}>

            <div className={classes.up}>
                <div>
                    <img src={trainer2} className={classes.img}></img>
                    <p className={classes.tname}>Dr.Mrinalini Kulkarni</p>
                </div>
                <div>
                    <img src={trainer1} className={classes.img}></img>
                    <p className={classes.tname}>Mr.Pankaj More</p>
                </div>
                <div>
                    <img src={trainer3} className={classes.img}></img>
                    <p className={classes.tname}>Harrshada More</p>
                </div>
                <div>
                    <img src={trainer4} className={classes.img}></img>
                    <p className={classes.tname}>Rohini Shelke</p>
                </div>

            </div>
            <div className={classes.up}>
                <div>
                    <img src={a1} className={classes.img}></img>
                    <p className={classes.tname}>Prajwal Kadam</p>
                </div>
                <div>
                    <img src={a4} className={classes.img}></img>
                    <p className={classes.tname}>Aditya More</p>
                </div>
                <div>
                    <img src={a3} className={classes.img}></img>
                    <p className={classes.tname}>Umesh Suryavanshi</p>
                </div>

            </div>
            <div className={classes.down}>

            </div>

        </div>

    </div>
};

export default Trainer;