import { useEffect } from "react";
import Contact from "../Components/Contact/Contact";
import Contact2 from "../Components/Contact/Contact2";

const ContactPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return <div>
        <Contact2></Contact2>
    </div>
};

export default ContactPage;