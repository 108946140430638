import classes from './AboutCard.module.css';
import icon from './asset/testimonial-user.png';

const AboutCard = (props) => {
    return (
        <div className={classes.hold}>
            <p className={classes.head1}>What student Say?</p>
            <p className={classes.head2}><span className={classes.colour}>OUR </span>STUDENT'S SAY</p>

            <div className={classes.card}>
                <div>
                    <img src={icon} className={classes.icon}></img>
                </div>
                <div className={classes.txtHold}>
                    <p className={classes.name}>{props.name}</p>
                    <p className={classes.txt}>{props.txt}</p>
                </div>
            </div>
        </div>
    )
}

export default AboutCard;