import classes from './Contact2.module.css';
import home from './home.png';
import mail from './mail.png';
import phone from './smartphone.png';
import linkedin from './linkedin.png';
import insta from './instagram.png';
import solid from './solid.png';



import React, { useRef } from 'react';






const Contact2 = () => {




    return (
        <div className={classes.hold}>
            <p className={classes.head}>Get In Touch</p>

            <div className={classes.hold2}>
                <p className={classes.msg}>SEND YOUR MESSAGE</p>
                <div className={classes.formH}>
                    <form action='https://formsubmit.co/saihccpune@gmail.com' method='POST' >
                        <p className={classes.msg2}>Fill Up The Form</p>
                        <input className={classes.in} placeholder='Name' name="user_name"></input>
                        <input className={classes.in} placeholder='Email' name="user_email"></input>
                        <br></br>
                        <input className={classes.in2} placeholder='Subject' name="user_subject"></input>
                        <br></br>
                        <textarea className={classes.in3} placeholder='Message' name="user_message"></textarea>
                        <br></br>
                        <button className={classes.btn}>Submit Request</button>
                    </form>
                </div>

            </div>

            <p className={classes.msg}>HEAD OFFICE</p>
            <div className={classes.addHoldMain}>
                <div className={classes.addHold}>
                    <p className={classes.msg2}>Reach Us</p>
                    <div className={classes.homeHold}>
                        <img className={classes.icon} src={home}></img>
                        <p className={classes.address}>Office No. 3, First Floor, Pashankar Auto pvt Lim. Jangali Maharaj Road Near Shivajinagar, Pune-411005</p>
                    </div><br></br>

                </div>

                <div className={classes.addHold}>
                    <p className={classes.msg2}>Drop A Mail</p>
                    <div className={classes.homeHold}>
                        <img className={classes.icon} src={mail}></img>
                        <p className={classes.address}>saihccpune@gmail.com</p>
                    </div>
                </div>

                <div className={classes.addHold}>
                    <p className={classes.msg2}>Call Us</p>
                    <div className={classes.homeHold}>
                        <img className={classes.icon} src={phone}></img>

                        <div><p className={classes.address}>+919404074769</p>
                            <p className={classes.more}>Mr.Pankaj More</p>
                            <p className={classes.address1}>+918530015155</p>
                        </div>

                    </div><br></br><br></br>

                </div>
            </div>
            <div className={classes.socialHold}>
                <a target="_blank" href='https://www.linkedin.com/in/sai-hcc-61105527b/'><img src={linkedin} alt='' className={classes.social}></img></a>
                <a target="_blank" href='https://www.instagram.com/saihccpune/'><img src={insta} alt='' className={classes.social}></img></a>
                <a target="_blank" href='https://www.facebook.com/saihccpune/'><img src={solid} alt='' className={classes.social1}></img></a>

            </div>

            <div className={classes.mapHold}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3783.042296777212!2d73.84858637348724!3d18.526990668981483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sOffice%20No.%203%2C%20First%20Floor%2C%20Pashankar%20Auto%20pvt%20Lim.%20Jangali%20Maharaj%20Road%20Near%20Shivajinagar%2C%20Pune-411005!5e0!3m2!1sen!2sin!4v1700890956055!5m2!1sen!2sin"
                    width="100%"
                    height="400"
                    style={{ border: "0" }}
                    allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
        </div>
    )
}

export default Contact2;