
import { useEffect } from "react";
import Course from "../Components/Course/Course";
import Material from "../Components/Course/Material";
import Training from "../Components/Course/Training";
import Flexible from "../Components/Course/Flexible";
import Exam from "../Components/Course/Exam";

const Courses = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return <div>
        <Course></Course>
        <Material></Material>
        <Training></Training>
        <Flexible></Flexible>
        <Exam></Exam>
    </div>
};

export default Courses;