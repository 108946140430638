import classes from './TestCard.module.css';
import img1 from './man.avif';
import star from './star.png';
import comma from './inverted-commas.png';

const TestCard = (props) => {
    return <div className={classes.hold}>
        <div className={classes.di}>
            <div className={classes.imgHold}>
                <img className={classes.img} src={props.img1}></img>
            </div>

            <div className={classes.commaHold}>
                <img src={comma} className={classes.comma}></img>
            </div>

            <div className={classes.headHold}>
                <p className={classes.head}>{props.name}</p>
            </div>

            <div className={classes.secRowHold}>
                <div className={classes.dateHold}>
                    <p className={classes.date}>{props.date}</p>
                </div>

                <div className={classes.rateHold}>
                    <p className={classes.rate}>{props.star}</p>
                    <img src={star} className={classes.starImg}></img>
                </div>
            </div>



            <div className={classes.txtHold}>
                <p className={classes.txt}>{props.txt}</p>
            </div>
        </div>


    </div>
};

export default TestCard;