import React, { Component } from "react";
import classes from './PopCard.module.css';
import Slider from "react-slick";
import MyCard from "./MyCard";
import img1 from './img1.webp';
import img2 from './img4.jpeg';
import img3 from './img3.jpg'
import images5 from './images5.jfif';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Popcard extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };
        return (
            <div className={classes.hold}>

                <Slider {...settings}>

                    <div>
                        <MyCard title='Certified Professional Coder'
                            image={img3}
                            mytext='Nowadays CPC certification has been a great option for fresher too who have been looking out for a career in medical coding'></MyCard>
                    </div>
                    <div>
                        <MyCard title='Basic Medical Coding Training'
                            image={img1}
                            mytext='BMC Training is purely a career entry platform which equips yourself to enter into Healhcare industry.'></MyCard>
                    </div>
                    <div>
                        <MyCard title='CPC Certification for freshers'
                            image={images5}
                            mytext='Nowadays CPC certification has been a great option for fresher too who have been looking out for a career in medical coding... (cpc)'></MyCard>
                    </div>

                </Slider>
            </div>
        );
    }
};
