import classes from './Testimonial.module.css';

const Testimonial = () => {
    return <div className={classes.hold}>


        <div className={classes.lineHold}>
            <div className={classes.testHold}>
                <span className={classes.test}>TESTIMONIALS</span>
            </div>

            <hr className={classes.line}></hr>
            <hr className={classes.line2}></hr>
        </div>

        <div className={classes.bestHold}>
            <span className={classes.best}>Best Medical coding Institute With&nbsp;</span>
            <span className={classes.star}>Excellent Ratings</span>
        </div>
    </div>
};

export default Testimonial;