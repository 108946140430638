import classes from './Training.module.css';

const Training = () => {
    return <div className={classes.hold}>
        <div className={classes.headhold}>
            <span className={classes.head1}>Training &nbsp; </span>
            <span className={classes.head2}>Duration</span>
        </div>

        <div className={classes.headhold2}>
            <span className={classes.head21}>3 to 4 months</span>
        </div>

        <div className={classes.txthold2}>
            <span className={classes.txt21}>Duration may also extend for
                candidates who are not confident enough to take exam
                or also unable to manage time, would be placed back in retaining
                or solving mock papers.<p>For above point, retraining can be for
                    specific topic or also complete retraining, if they want.</p></span>
        </div>
    </div>
};

export default Training;