import classes from './TestSlider.module.css';
import React, { Component } from "react";
import Slider from "react-slick";
import TestCard from './TestCard';



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import man from './man.png';
import woman from './woman.png';



export default class TestSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 2000,
            cssEase: "linear",
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className={classes.di}>

                <Slider {...settings}>
                    <div>
                        <TestCard img1={man} name='Kandy Bhosale' date='4 months ago' star='5'
                            txt='One of the best Medical Coding Classes in Maharashtra . All the trainers are experts in their teachings they could help at any point, class environment is very friendly. If you have single doubt trainer will teach at ground level they can help you on phone also.
Also study material, sample paper they also help you a lot in this journey,
this class must give you confidence, tips and tricks to clear this exam.'></TestCard>
                    </div>
                    <div>
                        <TestCard img1={man} name='Nikhil Mardhekar' date='9 months ago' star='5'
                            txt='One of the Best coding class in Pune. I honestly say that, the teaching is excellent. Best trainers I have experienced. Trainers are always available if we have any issues and they ensure doubts are cleared.All trainers are experienced. Lectures are interactive and the difficult test papers gets easier by giving mock test.'></TestCard>
                    </div>
                    <div>
                        <TestCard img1={woman} name='Megha Kale' date='3 months ago' star='4.8'
                            txt='Sai healthcare consulting is a one of the best medical coding class.The trainer is really good and explained me with many real-time examples. The institution has very good in infrastructure and also good for studying. I will surely recommend this classes if u want to improve your personal skills and knowledge in medical coding.'></TestCard>
                    </div>
                    <div>
                        <TestCard img1={man} name='Pratik Bankar' date='11 months ago' star='5'
                            txt='One of the best medical coding institution in Pune.I had join the class in August and in 3 months sir had completed the full syllabus with proper understanding.The staff is very helping and corporative they help you in every need. Class environment is very familiar and students are also corporative. Placement is also given by the sir in well recognised companies.'></TestCard>
                    </div>
                    <div>
                        <TestCard img1={woman} name='Rutuja Mhaske' date='1 year ago' star='4.9'
                            txt='Best Medical Coding Institute in Pune.This class provide ticks and tips to pass medical coding exam.It was great experience to learn medical coding with experienced teachers. Also provide study material, sample paper they also help you a lot of in this journey. I will suggest to join this class to my friends.'></TestCard>
                    </div>
                    <div>
                        <TestCard img1={man} name='Pawar Omkar' date='9 months ago' star='5'
                            txt='Best Medical Coding Institute in Pune. 
This institute provide tricks and tips to pass Medical coding exam. The trainers are very intelligent with experience. They can explain every medical term for the students who have not Medical Background. I will suggest to join classes if you want a career in Medical Coding Field.'></TestCard>
                    </div>
                    <div>
                        <TestCard img1={woman} name='Nikita Jadhav' date='2 months ago' star='5'
                            txt='Best institute for medical coding training. They guide you on the right path without giving any fake promises. The staff is really good and they took my interest in medical coding to another level. Thanks for the valuable support.'></TestCard>
                    </div>
                    <div>
                        <TestCard img1={woman} name='Komal Musmade' date='5 months ago' star='4.8'
                            txt='One of the Best coding class in Pune. I honestly say that, the teaching is excellent. Best trainers I have experienced. Trainers are always available if we have any issues and they ensure doubts are cleared.All trainers are experienced.'></TestCard>
                    </div>
                    <div>
                        <TestCard img1={woman} name='Shital More' date='1 months ago' star='5'
                            txt='SAI Healthcare Consulting is really great place for medical coding course.
Experienced and intelligent trainers are there who help you in exam preparation, clear your doubts, give you tricks. Trainers are always available if we face any issue, give u personal coaching if u face any issue until your doubts are cleared.. From my experience I would like to thanks Dr. Mrunalini kulkarni mam for there support during my exam preparation, training and Pankaj sir for guidance, boosting my confidence. So highly recommend SAI Healthcare consulting.'></TestCard>
                    </div>
                </Slider>
            </div>
        );
    }
}